export const defaultSupplierConfig = {
    supplierCompanyName: '',
    logo: {
        small: '',
        large: '',
        alt: ''
    },
    chatlio: {
        widgetId: '',
        widgetTitle: ''
    },
    auth0: {
        clientId: ''
    },
    misc: {
        deliveryComment: '',
        facilityTypes: []
    }
}

export const localhost = {
    supplierCompanyName: 'Localhost Supplier',
    logo: {
        small: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon.png',
        large: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-logo-large.png',
        alt: 'Dysport Logo'
    },
    chatlio: {
        widgetId: '',
        widgetTitle: 'Dysport Direct Support',
        logo: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon-square.png'
    },
    auth0: {
        clientId: 'n20sGi7hlL8qprFCaAOwnOHnJ6lN7kzT'
    },
    misc: {
        enableExpeditedShipping: true,
        deliveryComment: 'localhost delivery comment',
        facilityTypes: [
            'Special Localhost only option :)',
            'Ambulatory Center',
            'Central Fill',
            'Community Oncology',
            'Compounding',
            'Correctional Facility',
            'Infusion',
            'Inpatient',
            'Long Term Care',
            'Mail Order',
            'Outpatient',
            'Physician Office',
            'Retail',
            'Specialty',
            'Surgery Center',
            'Other',
        ]
    }
};


export const supplier1 = {
    supplierCompanyName: 'Eschelon RX',
    logo: {
        small: 'https://medigi-client-images.s3.us-west-2.amazonaws.com/hydra/echelon/e-logo.svg',
        large: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/eschelon-logo.png',
        alt: 'Eschelon RX Logo'
    },
    chatlio: {
        widgetId: '',
        widgetTitle: '',
        logo: ''
    },
    auth0: {
        clientId: 'sZ2oYnLQRCsq0FiojEsvz2v919fuIFME'
    },
    misc: {
        enableExpeditedShipping: true,
        deliveryComment: '',
        facilityTypes: [],
    }
};


export const supplier2 = {
    supplierCompanyName: 'Ipsen',
    logo: {
        small: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon.png',
        large: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-logo-large.png',
        alt: 'Dysport Logo'
    },
    chatlio: {
        widgetId: '',
        widgetTitle: 'Dysport Direct Support',
        logo: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon-square.png'
    },
    auth0: {
        clientId: 'n20sGi7hlL8qprFCaAOwnOHnJ6lN7kzT'
    },
    misc: {
        enableExpeditedShipping: false,
        deliveryComment: "Orders received M-Th by 12pm CST will ship for next day delivery.  All other orders will ship within 48 business hours.",
        facilityTypes: [
            'Neurology',
            'Pain Management',
            'Rehabilitation',
            'Pharmacy',
            'Other'
        ]
    }
};


export const supplier3 = {
    supplierCompanyName: 'Hikma',
    logo: {
        small: 'https://medigi-client-images.s3.us-west-2.amazonaws.com/hydra/hikma/Hik_Logo_SmallUse_Coral_rgb.png',
        large: 'https://medigi-client-images.s3.us-west-2.amazonaws.com/hydra/hikma/Hik_Logo_Primary_Coral_rgb.png',
        alt: 'Hikma Logo'
    },
    chatlio: {
        widgetId: 'e2221e67-6aa8-4831-7730-d930602c9168',
        widgetTitle: 'Hikma Support',
        logo: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/Hik_Logo_SmallUse_Coral_rgb_zoomedout.png'
    },
    auth0: {
        clientId: 'Pdw6cXvPzpJjCTIFlaeNFiw6n3Lp26hl'
    },
    misc: {
        enableExpeditedShipping: false,
        deliveryComment: "Orders received before 3:00 P.M. EST will be processed the following business day via complimentary ground shipping. Once an order has shipped it cannot be cancelled.",
        facilityTypes: [
            'Ambulatory Center',
            'Central Fill',
            'Community Oncology',
            'Compounding',
            'Correctional Facility',
            'Infusion',
            'Inpatient',
            'Long Term Care',
            'Mail Order',
            'Outpatient',
            'Physician Office',
            'Retail',
            'Specialty',
            'Surgery Center',
            'Other'
        ]
    }
};


export const supplier4 = {
    supplierCompanyName: 'Chiesi',
    logo: {
        small: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/cheisi_icon.png',
        large: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/chiesi_logo_blk.png',
        alt: 'Chiesi Logo'
    },
    chatlio: {
        widgetId: '',
        widgetTitle: 'Chiesi Support',
        logo: '',
    },
    auth0: {
        clientId: 'Nxz2J28pFZ0TL7a6G7qZKeKHiXHd1doJ'
    },
    misc: {
        enableExpeditedShipping: false,
        deliveryComment: '',
        facilityTypes: []
    }
};


export const dysportdirect = {
    supplierCompanyName: 'Ipsen',
    logo: {
        small: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon.png',
        large: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-logo-large.png',
        alt: 'Dysport Logo'
    },
    chatlio: {
        widgetId: '77000286-27b2-4d4c-5eda-e18a2e2a2a19',
        widgetTitle: 'Dysport Direct Support',
        logo: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/dysport-icon-square.png'
    },
    auth0: {
        clientId: 'JZuk4aA8PBJdpUL0oYfntYiozj20esJj'
    },
    misc: {
        enableExpeditedShipping: false,
        deliveryComment: "Orders received M-Th by 12pm CST will ship for next day delivery.  All other orders will ship within 48 business hours.",
        facilityTypes: [
            'Neurology',
            'Pain Management',
            'Rehabilitation',
            'Pharmacy',
            'Other'
        ]
    }
}

export const hikmadirect = {
    supplierCompanyName: 'Hikma',
    logo: {
        small: 'https://medigi-client-images.s3.us-west-2.amazonaws.com/hydra/hikma/Hik_Logo_SmallUse_Coral_rgb.png',
        large: 'https://medigi-client-images.s3.us-west-2.amazonaws.com/hydra/hikma/Hik_Logo_Primary_Coral_rgb.png',
        alt: 'Hikma Logo'
    },
    chatlio: {
        widgetId: 'e2221e67-6aa8-4831-7730-d930602c9168',
        widgetTitle: 'Hikma Support',
        logo: 'https://medigi-tmp-docs.s3.us-west-2.amazonaws.com/hydra-logos/Hik_Logo_SmallUse_Coral_rgb_zoomedout.png'
    },
    auth0: {
        clientId: 'oO9UNz0vr501PHplc42JkZ4QonZdOPUp'
    },
    misc: {
        enableExpeditedShipping: false,
        deliveryComment: "Orders received before 3:00 P.M. EST will be processed the following business day via complimentary ground shipping. Once an order has shipped it cannot be cancelled.",
        facilityTypes: [
            'Ambulatory Center',
            'Central Fill',
            'Community Oncology',
            'Compounding',
            'Correctional Facility',
            'Infusion',
            'Inpatient',
            'Long Term Care',
            'Mail Order',
            'Outpatient',
            'Physician Office',
            'Retail',
            'Specialty',
            'Surgery Center',
            'Other'
        ]
    }
}